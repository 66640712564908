.cf-expert-verification {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
}

.cf-expert-verification > .cf-centered-spinner {
    height: 90vh;
}

