.cf-card {
    width: var(--card-width-xs);
    margin: 0 4px 8px 4px;
}

.cf-card-header {
    display: inline-flex;
    justify-content: space-between;
}
@media (min-width: 576px) {  /* sm */
    .cf-card {
        width: var(--card-width-lg);
        margin: 0 4px 8px 4px;
    }
}

@media (min-width: 1200px) {  /* xl */
    .cf-card {
        width: var(--card-width-xl);
        margin: 0 8px 16px 8px;
    }
}