.cf-comment-dropdown-button {
    width: 100%;
}

.cf-comment-menu {
    height: 600px;
    width: 100%;
}

.cf-comment-menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 8px 16px;
}

.cf-comment-menu-comments {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.cf-comment-menu-comment {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--bs-border-color);
    width: 90%;
    margin: 0 auto;
}

@media (min-width: 768px) {  /* md */
    .cf-comment-menu {
        resize: both;
        overflow: auto;
        height: 600px;
        width: 500px;
        min-height: 500px;
        min-width: 500px;
    }
}