.cf-identification {
    width: 100%;
    margin: 16px 0;
    padding-bottom: 32px;
}

.cf-identification-header {
    margin-bottom: 16px;
}

.cf-identification-form-group {
    border-top: 1px solid gray;
    width: 720px;
    margin: 0 auto;
    padding: 24px 16px 8px 16px;
}
