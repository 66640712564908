.cf-location-dropdown-button {
    width: 100%;
}

.cf-location-dropdown {
    height: 500px;
    width: 100%;
}

.cf-location-dropdown-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.cf-location-dropdown .cf-search-bar {
    width: 90%;
    margin: 16px auto;
}

.cf-location-dropdown .cf-paginator {
    margin: 16px 0;
}

@media (min-width: 768px) {  /* md */
    .cf-location-dropdown {
        resize: both;
        overflow: auto;
        height: 500px;
        width: 500px;
        min-height: 500px;
        min-width: 500px;
    }
}