.cf-species-card-toxicity-status {
    position: absolute;
    top: 60px;
    left: 20px;
}

.cf-species-card-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0 0 0;
}
