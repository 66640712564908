.cf-species-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
}

.cf-species-list > .cf-centered-spinner {
    height: 90vh;
}
