.cf-navbar {
    background-color: rgb(20, 16, 33);
}

.cf-navbar-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: inherit;
}

.cf-navbar-group {
    --bs-navbar-nav-link-padding-x: 0;
    /*display: inline-flex;*/
    /*align-items: flex-end;*/
}

.cf-navbar-title {
    background: linear-gradient(to right, #6366f1, #8B5CF6, #D946EF);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2rem;
    text-decoration: none;
    margin: 0 8px 0 0;
}

.cf-navbar-link {
    color: rgba(255, 255, 255, 0.65) !important;
    font-size: 16px;
    font-weight: 600;
    margin: 0 8px 6px 8px;
}

.cf-navbar-link:hover,
.cf-navbar-link:focus {
    color: rgba(255, 255, 255, 0.8) !important;
}

.cf-navbar-link-active {
    color: var(--bs-purple-300) !important;;
}

.cf-navbar-link-active:hover,
.cf-navbar-link-active:focus {
    color: var(--bs-purple-200) !important;;
}

@media (min-width: 992px) {  /* lg */
    .cf-navbar {
        height: 72px;
    }

    .cf-navbar-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .cf-navbar-collapse {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
    }

    .cf-navbar-group {
        display: inline-flex;
        align-items: flex-end;
    }
}