.cf-image-grid {
    display: flex;
    flex-direction: row;
    /*padding: 8px;*/
    height: 380px;
}

.cf-image-grid-left {
    height: 380px;
}

.cf-image-grid-left:not(:last-child) {
    /* add margin only in cases where right part is present */
    margin-right: 4px;
}

.cf-image-grid-right {
    height: 380px;
}

.cf-image-grid-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
}

.cf-image-grid-image-small {
    width: 100%;
    height: 188px; /* (380 - 4) / 2 px */
    object-fit: cover;
    cursor: pointer;
}

.cf-image-grid-image-small:not(:last-child) {
    /* add margin only in cases where right part is present */
    margin-bottom: 4px;
}

.cf-image-grid-modal > .modal-dialog {
    width: 90vw;
    max-width: 90vw;
    height: 90vh;
    max-height: 90vh;
}

.cf-image-grid-carousel {

}

.cf-image-grid-carousel .carousel-control-prev-icon,
.cf-image-grid-carousel .carousel-control-next-icon {
    background-color: var(--bs-primary);
}

.cf-image-grid-carousel-item {

}

.cf-image-grid-carousel-image {
    object-fit: cover;
    display: block;
    margin: 0 auto;
}
