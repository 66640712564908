.cf-image-input {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
}

.cf-image-input-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    margin: 16px;
    height: 220px;
    border: 2px dashed var(--bs-primary);
    border-radius: var(--bs-border-radius);
    color: var(--bs-primary);
}

.cf-image-input-item.added {
    border: none;
}

.cf-image-input-item:hover,
.cf-image-input-item:focus {
    background-color: var(--bs-gray-100);
    cursor: pointer;
}

.cf-image-input-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
