.cf-my-observations {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
}

.cf-my-observations > .cf-centered-spinner {
    height: 90vh;
}

