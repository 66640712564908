.cf-log-in {
    background-color: #ffffff;
    width: 480px;
    padding: 48px;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px;
    margin: 32px auto;
}

.cf-log-in-header {
    text-align: center;
    margin-bottom: 24px;
}

.cf-log-in-form {
    margin-top: 24px;
}

.cf-log-in-form-button {
    width: 100%;
}