.cf-observation-card-classification {
    position: absolute;
    top: 60px;
    left: 20px;
}

.cf-observation-card-badge-ai {
    background-color: var(--gray-600);
}

.cf-observation-card-badge-author {
    background-color: var(--blue-600);
}

.cf-observation-card-badge-verification-requested {
    background-color: var(--purple-600);
}

.cf-observation-card-text {
    /*display: flex;*/
    /*flex-direction: row;*/
    padding-top: 16px;
    margin: 0;
}

.cf-observation-card-metadata {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
}

.cf-observation-card-buttons {
    display: flex;
    flex-direction: column;
    padding: 4px 0 0 0;
}

.cf-observation-card-buttons > *:not(:last-child) {
    margin-bottom: 4px;
}

@media (min-width: 768px) {  /* md */
    .cf-observation-card-buttons {
        padding: 0 0 0 4px;
    }
}
