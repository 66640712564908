.cf-species-select {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cf-species-select-search {
    width: 100%;
}

.cf-species-select-paginator {
    margin: 16px 0;
}

.cf-species-select-species-list {
    height: 500px;
    width: 100%;
    overflow-y: auto;
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.cf-species-option {
    display: inline-flex;
    flex-direction: row;
    height: 120px;
    width: 100%;
}
.cf-species-option.checked {
    background-color: var(--bs-purple-300);
}

.cf-species-option-image {
    width: 33%;
    max-height: 100%;
    object-fit: cover;
    margin-right: 8px;
}

.cf-species-option-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}