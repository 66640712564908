.cf-container {
    margin: 0 auto;
    max-width: 97%;
}

@media (min-width: 576px) {  /* sm */
    .cf-container {
        max-width: 560px;
    }
}

@media (min-width: 768px) {  /* md */
    .cf-container {
        max-width: 740px;
    }
}

@media (min-width: 992px) {  /* lg */
    .cf-container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {  /* xl */
    .cf-container {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {  /* xxl */
    .cf-container {
        max-width: 1320px;
    }
}

@media (min-width: 1800px) {  /* xxl */
    .cf-container {
        max-width: 1740px;
    }
}
