.cf-species-menu {
    height: 80%;
    width: 100%;
    overflow-y: auto;
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.cf-species-menu-item {
    display: inline-flex;
    flex-direction: row;
    height: 120px;
}

.cf-species-menu-item.dropdown-item:active {
    background-color: #9179EF;
}

.cf-species-menu-item-image {
    width: 33%;
    max-height: 100%;
    object-fit: cover;
    margin-right: 8px;
}

.cf-species-menu-item-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
