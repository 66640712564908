.cf-footer {
    background-color: rgb(20, 16, 33);
    color: rgba(255, 255, 255, 0.65);
    height: 80px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cf-footer a {
    color: rgba(255, 255, 255, 0.65);
    font-weight: 600;
    text-decoration: none;
    margin-left: 4px;
}

.cf-footer a:hover {
    color: rgba(255, 255, 255, 0.8);
}
