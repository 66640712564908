.cf-search-bar {
    width: var(--card-width-xs);
}

@media (min-width: 576px) {  /* sm */
    .cf-search-bar {
        width: var(--card-width-lg);
    }
}

@media (min-width: 1200px) {  /* xl */
    .cf-search-bar {
        width: var(--card-width-xl);
    }
}
