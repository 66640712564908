.cf-genus-menu {
    height: 80%;
    width: 100%;
    overflow-y: auto;
    padding-left: 0;
    list-style: none;
    margin: 0;
}

.cf-genus-menu-item {
    display: inline-flex;
    flex-direction: row;
    height: 48px;
}

.cf-genus-menu-item.dropdown-item:active {
    background-color: #9179EF;
}

.cf-genus-menu-item-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
